import React, { useEffect, useState } from 'react';
import { Icons } from '../assets/imageIndex';

const CounterCard = ({ count, label, icon }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((prev) => {
                if (prev < count) {
                    return prev + 1;
                }
                clearInterval(interval);
                return prev;
            });
        }, 50);

        return () => clearInterval(interval);
    }, [count]);

    return (
        <div className="counter-card flex flex-col items-center p-4 bg-white shadow rounded-lg">
            <div className="icon mb-2">
                <img src={icon} className='w-14' alt={label} />
            </div>
            <div className="content text-center">
                <h3 className="title text-3xl font-medium">
                    <span className="odometer mb-0 mt-5">{value}+</span>
                </h3>
                <p>{label}</p>
            </div>
        </div>
    );
};

const CounterSection = () => {
    return (
        <div className="process-counter px-4 md:px-0 pt-12 mt-12">
            <div className="full-width mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
                    <div data-aos="fade-up" data-aos-duration="1500">
                        <div className="col-lg-4 col-md-6">
                            <CounterCard
                                count={10}
                                label="Years of Experience"
                                icon={Icons.experience}
                            />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500">
                        <div className="col-lg-4 col-md-6">
                            <CounterCard
                                count={200}
                                label="Projects Complete"
                                icon={Icons.success}
                            />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500">
                        <div className="col-lg-4 col-md-6">
                            <CounterCard
                                count={250}
                                label="Team Members"
                                icon={Icons.teamIcon}
                            />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500">
                        <div className="col-lg-4 col-md-6">
                            <CounterCard
                                count={100}
                                label="Our Success Rate"
                                icon={Icons.premiumQuality}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterSection;
