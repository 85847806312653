import React from "react";
import { FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { FaSquareTwitter } from "react-icons/fa6";
import { Icons } from "../../assets/imageIndex";
import { Link } from "react-router-dom";
import { technologiesData } from "../../constant";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-black-800 text-white">
      <div className="text-white py-16" style={{ backgroundColor: '#111' }}>
        <div className="full-width mx-auto px-4">
          <div className="md:flex inline gap-12">

            {/* Logo and Description Section */}
            <div className="mb-1 w-full md:w-1/4 md:mb-0 text-left">
              <img className="w-44 h-auto mb-2" src={Icons.logoWhite} alt="" />
              <p className="text-gray-400 my-4" style={{ lineHeight: '20px' }}>
                At Covantis Sarvagis Technologies Private Limited, we are dedicated to delivering cutting-edge technological solutions that drive transformation. As a dynamic player in the tech industry, we focus on building impactful software, web, and digital products for local and global markets.
              </p>
              {/* <div className="flex justify-left space-x-4">
                <button className="link-class text-gray-400 hover:text-white"><FaInstagram size={24} /></button>
                <button className="link-class text-gray-400 hover:text-white"><FaWhatsapp size={24} /></button>
                <button className="link-class text-gray-400 hover:text-white"><FaYoutube size={24} /></button>
                <button className="link-class text-gray-400 hover:text-white"><FaSquareTwitter size={24} /></button>
              </div> */}
            </div>

            {/* Quick Links Section */}
            <div className="mb-1 md:mb-0 w-full md:w-1/6 text-left">
              <h5 className="text-lg text-orange-600 font-semibold mt-5 md:mt-0 mb-4">Quick Links</h5>
              <ul className="list-none space-y-2">
                <li><Link to={'/'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Home</Link></li>
                <li><Link to={'/services'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Services</Link></li>
                <li><Link to={'/about'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">About</Link></li>
                <li><Link to={'/contact'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Contact</Link></li>
              </ul>
            </div>

            {/* Services Section */}
            <div className="mb-1 md:mb-0 w-full md:w-1/3 text-left">
              <h5 className="text-lg mt-5 md:mt-0 text-orange-600 font-semibold mb-4">Our Services</h5>
              <ul className="list-none space-y-2">
                <li><Link to={`/serviceDetail/${'mobile-app-development'}`} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Mobile App Development</Link></li>
                <li><Link to={'/serviceDetail/web-development'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Web Development</Link></li>
                <li><Link to={'/serviceDetail/custom-software-development'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Custom Software Development</Link></li>
                <li><Link to={'/serviceDetail/ai-development'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">AI Development</Link></li>
                <li><Link to={'/serviceDetail/ui-ux-design'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">UI/UX Design</Link></li>
                <li><Link to={'/serviceDetail/cloud-services'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Cloud Services</Link></li>
                <li><Link to={'/serviceDetail/social-network-development'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Social Network Development</Link></li>
                <li><Link to={'/serviceDetail/business-intelligence'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Business Intelligence</Link></li>
                <li><Link to={'/serviceDetail/data-analytics'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Data Analytics</Link></li>
                <li><Link to={'/serviceDetail/e-commerce-solutions'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">E-Commerce Solutions</Link></li>
                <li><Link to={'/serviceDetail/it-consulting'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">IT Consulting</Link></li>
                <li><Link to={'/serviceDetail/cybersecurity-services'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Cybersecurity</Link></li>
                <li><Link to={'/serviceDetail/devops-services'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">DevOps</Link></li>
                <li><Link to={'/serviceDetail/digital-marketing-services'} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">Digital Marketing</Link></li>
              </ul>
            </div>


            {/* Technologies Section */}
            <div className="mb-1 md:mb-0 w-full md:w-1/4 text-left">
              <h5 className="text-lg mt-5 md:mt-0 text-orange-600 font-semibold mb-4">Technologies</h5>
              <ul className="list-none space-y-2">
                {technologiesData?.map((v) =>
                  <li><Link to={`/serviceDetail/${v}`} onClick={handleScrollToTop} className="text-gray-400 hover:text-white">{v}</Link></li>
                )}
              </ul>
            </div>

            {/* Contact Widget Section */}
            <div className="mb-1 md:mb-0 w-full md:w-1/4 text-left">
              <h5 className="text-lg text-orange-600 font-semibold mt-5 md:mt-0 mb-4">Have a Question?</h5>
              <div className="widget-links">
                <ul className="space-y-1">
                  {/* <li className="text-gray-400">Call Us 24/7 For Business Inquiry</li>
                  */}
                  {/* <li>
                    <a href="tel:+91 9915796279" className="text-gray-400 hover:underline">+91 9915796279</a>
                  </li> */}
                  <p className="text-gray-400 font-semibold">Drop a Email</p>
                  <li>
                    <a href="mailto:info@covantis.in" className="text-gray-400 hover:underline">info@covantis.in</a>
                  </li> 

                  {/* <li className="text-gray-400">Email Us For Career Inquiries</li> */}
                  {/* <li>
                    <a href="tel:+91 1234567890" className="text-gray-400 hover:underline">+91-12345-67890</a>
                  </li> */}
                  {/* <li>
                    <a href="mailto:neeraj.b@pixoatic.com" className="text-gray-400 hover:underline">info@covantis.com</a>
                  </li> */}
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Bottom Footer */}
      <div className="bg-black pt-4 md:text-center text-left pb-3 md:px-0 px-5">
        <div className="full-width mx-0 md:mx-auto">
          <div className="md:flex inline justify-between">
            {/* Privacy and Terms */}
            <div>
              <ul className="md:flex inline space-x-0 md:space-x-4">
                <li>
                  <Link to={`/privacyPolicy`} onClick={handleScrollToTop} className="text-white hover:text-white">Privacy Policy</Link>
                </li>
                <li>
                  <Link to={`/termsConditions`} onClick={handleScrollToTop}
                    className="text-white hover:text-white">Terms & Conditions</Link>
                </li>
              </ul>
            </div>

            {/* Copyright */}
            <div>
              <p className="text-white md:pb-0 pb-2 mb-0">© 2024 All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
